import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';


import { navLinks, reportTypes, tabLinks } from '../../constants/navlinks.constant';
import { Heading, Info, NavBar, NavItem } from './index.styles';

import { ArrowDown as ArrowUp } from '../../assets/icons';
import Education from './education.component';
import Health from './health.component';
import CultureSport from './culture-sport.component';
import AccessRoad from './access-road.components';
import GasSupply from './gas-supply.component';
import Internet from './internet.component';
import Lighting from './lighting.component';
import VillageRoad from './village-road.component';
import WaterSupply from './water-supply.component';
import VillageImprovement from './village-improvement.component';
import Investment from './investment.component';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (tab: string) => void;
}

const Content: React.FC<IProps> = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kato, link, tab } = useParams();
  const contentRef = useRef<HTMLDivElement>(null);

  const [selectedReport, setSelectedReport] = useState<string>(reportTypes[0]);
  const [content, setContent] = useState<JSX.Element | null>(null);

  const handleSelectReport = (report: string) => {
    setSelectedReport(report);
    !navLinks.map(item => item.name).includes(kato as any) ? navigate(`/${kato}/infrastructure-projects/${report}`) : navigate(`/infrastructure-projects/${report}`)
  }

  const getHeading = useCallback(() => {
    if (tabLinks.includes(tab as string)) {
      return t(`report_link_types.${tab}`)
    } else if (['info', 'analytics', 'akim'].includes(selectedTab as string)) {
      return selectedTab === 'analytics' ? t('snpAnalytics') : t(selectedTab as string)
    } else {
      return t(`navLinks.${selectedTab}`);
    }
  }, [t, tab, selectedTab])

  const loadReport = useCallback(() => {
    if (selectedReport && kato) {
      switch (selectedReport) {
        case 'education':
          setContent(<Education kato={+kato} contentRef={contentRef} />)
          break;
        case 'health':
          setContent(<Health kato={+kato} contentRef={contentRef} />)
          break;
        case 'culture':
          setContent(<CultureSport kato={+kato} contentRef={contentRef} />)
          break;
        case 'driveway':
          setContent(<AccessRoad kato={+kato} contentRef={contentRef} />)
          break;
        case 'gas':
          setContent(<GasSupply kato={+kato} contentRef={contentRef} />)
          break;
        case 'internet':
          setContent(<Internet kato={+kato} contentRef={contentRef} />)
          break;
        case 'light':
          setContent(<Lighting kato={+kato} contentRef={contentRef} />)
          break;
        case 'countryroad':
          setContent(<VillageRoad kato={+kato} contentRef={contentRef} />)
          break;
        case 'water':
          setContent(<WaterSupply kato={+kato} contentRef={contentRef} />)
          break;
        default:
          setContent(<Education kato={+kato} contentRef={contentRef} />)
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport])

  const handleGoUp = () => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (kato) {
      switch (selectedTab) {
        case 'infrastructure-projects':
          loadReport();
          break;
        case 'snp_improvement':
          setContent(<VillageImprovement kato={+kato} contentRef={contentRef} />)
          break;
        case 'snp_investment':
          setContent(<Investment kato={+kato} contentRef={contentRef} />)
          break;
        default:
      }
    }
  }, [loadReport, selectedTab])

  useEffect(() => {
    if (tab) {
      setSelectedReport(tab)
    }
  }, [tab, setSelectedTab])

  return (
    <>
      <Heading>
        <span>
          {getHeading()}
        </span>
      </Heading >
      <Info>
        {selectedTab === 'infrastructure-projects' &&
          <NavBar>
            {tabLinks.map((report) =>
              <NavItem key={report} selected={selectedReport === report} onClick={() => handleSelectReport(report)}>
                <p> {t(`report_link_types.${report}`)}</p>
              </NavItem>
            )}
          </NavBar>
        }
        <div className="content">
          {content}
          <div className="go-up" onClick={handleGoUp}><ArrowUp /></div>
        </div>
      </Info>
    </>
  )
}

export default Content