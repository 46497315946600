import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { HeaderContainer, Item, TabButton } from './index.styles';
import { headerOptions } from '../../constants/navlinks.constant';
import { ArrowRight, DeleteX } from '../../assets/icons';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (tab: string) => void;
  onClose: () => void;
  toggleShowLeftMenu: () => void;
  showLeftMenu: boolean;
}

const Header: React.FC<IProps> = ({ selectedTab, setSelectedTab, onClose, toggleShowLeftMenu, showLeftMenu }) => {
  const { t } = useTranslation();



  return (
    <HeaderContainer >
      <div className="toggle" onClick={toggleShowLeftMenu}> <ArrowRight width={10} fill='var(--primary-color)' style={{ transform: showLeftMenu ? 'rotate(180deg)' : 'rotate(0deg)' }} /> </div>
      <Item full withScroll>
        {headerOptions.map((option) => (
          <TabButton
            key={option}
            selected={selectedTab === option} 
            onClick={() => setSelectedTab(option)}>
            {t(option)}
          </TabButton>
        ))}
      </Item>

      <Item onClick={onClose} className='close-button'>
        <DeleteX />
      </Item>
    </HeaderContainer >
  )
}

export default Header