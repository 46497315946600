import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getProjectList } from '../../requests/snp.request';
import { AgricultureWrapper, EducationContainer, EducationItem } from './index.styles';
import { transformList } from './education.component';

import Documentation from './documentation.component';
import ImageGrid from './image-grid.component';
import ZoomableImageModal from './zoomable-image.component';
import AdditionalFields from './additional-fields.component';
import { checkNonEmpty, checkVal, getLang } from '../../utils/helpers.utils';
import { ArrowDown } from '../../assets/icons';

const VillageImprovement = ({ kato, contentRef }: { kato: number, contentRef: any }) => {

  const { t } = useTranslation();

  const [projectList, setProjectList] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [objectName, setObjectName] = useState<string>('Фото')

  const [isAllOpen, setIsAllOpen] = useState<boolean>(false);
  const [isListOpen, setIsListOpen] = useState<{ [key: string]: boolean }>({});

  const handleOpen = (id: any) => {
    setIsListOpen(prev => ({ ...prev, [id]: !prev[id] }));
  }

  const handleOpenAll = () => {
    const obj = Object.keys(isListOpen).reduce((acc: any, key: any) => {
      acc[key] = !isAllOpen;
      return acc;
    }, {})

    setIsListOpen(obj);
  }

  useEffect(() => {
    const obj = projectList.reduce((acc: any, street: any) => {
      acc[street.id] = projectList.length < 3;
      return acc;
    }, {})
    setIsListOpen(obj);

    setIsAllOpen(Object.values(obj).every((value: any) => value === true));
  }, [projectList])

  useEffect(() => {
    setIsAllOpen(Object.values(isListOpen).every((value: any) => value === true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(isListOpen)])

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setSelectedImage(image);
    const arr: any = (Object.values(images).reduce((acc: any, item: any) => [...acc, ...item], []));
    const imageList = (arr.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []));
    setCurrentImageList(imageList);
    setObjectName(name);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  useEffect(() => {
    const snpData = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpData.kato
    if (val) {
      getProjectList('SNP_IMPROVEMENT', val)
        .then((res) => {
          const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
          setProjectList(transformList(filtered));
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
    }
  }, [])

  useEffect(() => {
    getProjectList('SNP_IMPROVEMENT', kato)
      .then((res) => {
        const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
        setProjectList(transformList(filtered));
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects-improvement')} 123</p>
  }
  return (
    <AgricultureWrapper>
      <div className='list-heading with-icon' onClick={handleOpenAll}>
        {t('list')}
        <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
      </div>

      <EducationContainer ref={contentRef}>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading with-icon" onClick={() => handleOpen(project.id)}>
              <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
              {project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}
            </div>

            {isListOpen[project.id] && <div className='body'>

              <Documentation project={project} />

              {checkVal(project.additionalFields) && Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).length > 0 && <AdditionalFields project={project} />}

              {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`} />}

            </div>
            }

          </EducationItem>
        )}
        <ToastContainer />
      </EducationContainer>
      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={currentImageList}
          objectName={objectName}
        />
      )}
    </AgricultureWrapper>

  )
}

export default VillageImprovement;
