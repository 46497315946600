import React from 'react';
import { navLinks } from '../../constants/navlinks.constant';
import { useTranslation } from 'react-i18next';

import { NavBarWrapper, NavItem, NavIcon } from './index.styles';
import { useNavigate, useParams } from 'react-router-dom';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (link: string) => void;
}

const NavBar: React.FC<IProps> = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { kato, tab } = useParams();

  const handleSelectLink = (link: string) => {
    setSelectedTab(link);
    link !== 'infrastructure-projects'
      ? navigate(`/${kato}/${link}`)
      : tab !== 'undefined' || tab !== undefined
        ? navigate(`/${kato}/${link}/${tab}`)
        : navigate(`/${kato}/${link}/education`)
  }

  return (
    <NavBarWrapper>
      {navLinks.map((link) =>
        <NavItem
          key={link.name}
          onClick={() => handleSelectLink(link.name)}
          isSelected={selectedTab === link.name}>
          <NavIcon>
            <img src={link.icon} alt={link.name} />
          </NavIcon>
          <div className="text">{t(`navLinks.${link.name}`)}</div>
        </NavItem>)
      }
    </NavBarWrapper>
  )
}

export default NavBar