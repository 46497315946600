import React from 'react'
import { BasicStatsItem } from '../index.styles'

const Livestock = () => {
  const link = 'https://app.powerbi.com/view?r=eyJrIjoiNjVmMjc4OWMtYjcxOS00NThlLWFkOWItMGQzMjcyOWMyOGY1IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9'
  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
      }}>
      <iframe title="livestock iframe" src={link} />
    </BasicStatsItem>
  )
}

export default Livestock