import React, { useEffect } from 'react'
import { Expand } from '../../assets/icons';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ZoomableImageContainer } from './index.styles';
import Modal from '../modal-component';
import { getImageUrl } from '../admin-page/general-info.component';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  selectedImage: number | null;
  setSelectedImage: (imageId: number) => void;
  isFileUpload?: boolean;
  currentImageList: number[]
  objectName?: any;
}

const ZoomableImageModal: React.FC<IProps> = ({ isOpen, onClose, isFileUpload, selectedImage, setSelectedImage, currentImageList }) => {

  const handleNextImage = () => {
    if (selectedImage) {
      const currentIndex = currentImageList.indexOf(selectedImage);
      if (currentIndex < currentImageList.length - 1) {
        setSelectedImage(currentImageList[currentIndex + 1]);
      }
    }
  }

  const handlePrevImage = () => {
    if (selectedImage) {
      const currentIndex = currentImageList.indexOf(selectedImage);
      if (currentIndex > 0) {
        setSelectedImage(currentImageList[currentIndex - 1]);
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.code === 'ArrowRight') {
        handleNextImage()
      }

      if (e.code === 'ArrowLeft') {
        handlePrevImage()
      }
    })
  }, [selectedImage])

  return (
    <Modal isFileUpload={isFileUpload} isOpen={isOpen} onClose={onClose} hasBg={false}>
      <ZoomableImageContainer>
        <button onClick={handlePrevImage}>{'<'}</button>
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <div className="tools">
                <button className='zoom' onClick={() => zoomIn()}>+</button>
                <button className='zoom' onClick={() => zoomOut()}>-</button>
                <button className='zoom' onClick={() => resetTransform()}><Expand /> </button>
              </div>
              {selectedImage && <TransformComponent>
                <img src={getImageUrl(+selectedImage)} alt="object" />
              </TransformComponent>}
            </>
          )}
        </TransformWrapper>
        <button onClick={handleNextImage}>{'>'}</button>

      </ZoomableImageContainer>
    </Modal>

  )
}

export default ZoomableImageModal