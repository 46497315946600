import React, { useEffect, useState } from 'react'
import { IMarker, IRegion } from '../../interfaces/snp.interface'

import { CurrentSnpWrapper, Image, SnpInfo } from './index.styles'
import { DefaultGerb, ProdBackground, TestBackground } from '../../assets/images'
import { useTranslation } from 'react-i18next';
import { getLang, OBLAST_KATO } from '../../utils/helpers.utils';
import {
  gerb391600000,
  gerb392400000,
  gerb393200000,
  gerb393400000,
  gerb393600000,
  gerb394000000,
  gerb394200000,
  gerb394400000,
  gerb394800000,
  gerb395000000,
  gerb395200000,
  gerb395400000,
  gerb395600000,
  gerb395800000,
  gerb396200000,
  gerb396400000,
  gerb396600000,
  gerb396800000
} from '../../assets/images/gerbs/gerbs'
import { useNavigate, useParams } from 'react-router-dom';
import { getRegionList, getSettlementTree } from '../../requests/kato.request';
import { aktobeOblast } from './search.component';

interface IProps {
  selectedRegion: IRegion;
  selectedSnp: IMarker;
  handleSelectOption: (option: any) => void;
}

const CurrentSnp: React.FC<IProps> = ({ selectedRegion, selectedSnp, handleSelectOption }) => {
  const { t, i18n: { language } } = useTranslation();
  const { kato } = useParams();
  const navigate = useNavigate();
  const [bgImage, setBgImage] = React.useState<string>('')
  const [gerb, setGerb] = React.useState<string>(DefaultGerb);
  const [regions, setRegions] = useState<any[]>([])
  const [snps, setSnps] = useState<any[]>([])
  const [region, setRegion] = useState<any>(null)
  const [snp, setSnp] = useState<any>(null)

  const getGerb = () => {
    switch (selectedRegion?.kato) {
      case 391600000:
        return gerb391600000;
      case 392400000:
        return gerb392400000;
      case 393200000:
        return gerb393200000;
      case 393400000:
        return gerb393400000;
      case 393600000:
        return gerb393600000;
      case 394000000:
        return gerb394000000;
      case 394200000:
        return gerb394200000;
      case 394400000:
        return gerb394400000;
      case 394800000:
        return gerb394800000;
      case 395000000:
        return gerb395000000;
      case 395200000:
        return gerb395200000;
      case 395400000:
        return gerb395400000;
      case 395600000:
        return gerb395600000;
      case 395800000:
        return gerb395800000;
      case 396200000:
        return gerb396200000;
      case 396400000:
        return gerb396400000;
      case 396600000:
        return gerb396600000;
      case 396800000:
        return gerb396800000;
      default:
        return DefaultGerb
    }
  }

  useEffect(() => {
    const hostname = window.location.host;
    const bg = hostname.includes('auyl-aktobe.kz') ? ProdBackground : TestBackground;
    setBgImage(ProdBackground);
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      setGerb(getGerb);
    } else {
      setGerb(DefaultGerb);
    }
  }, [selectedRegion]);

  const handleRegionClick = () => {
    handleSelectOption(aktobeOblast);
  }

  useEffect(() => {
    getSettlementTree().then((res: any) => {
      getRegionList().then((regionRes: any) => {
        const withChildren = (Object.keys(res.data)
          .map((item: any) => ({
            key: item, children: Object.keys(res.data[item])
              .map((key: string) => {
                const { pnameKz, pnameRu, pkato } = res.data[item][key][0]
                return ({ value: pkato, label: pnameRu, labelKz: pnameKz })
              })
          })))

        const regions = withChildren.map((elem: any) => ({ ...elem, ...regionRes.find((item: any) => [item.nameRu, item.nameKz].includes(elem.key)) }))
        const filtered = regions.map((item: any) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz, ...item }))
        setRegions(filtered)
        setSnps(filtered[0].children);
      })
    });
  }, [])

  useEffect(() => {
    if (kato) {
      const found = regions.find((item: any) => item.children.find((item: any) => +item.value === +kato));
      if (found) {
        setRegion(found[`name${getLang()}`]);
        setSnps(found.children);
        const snpName = found.children.find((item: any) => +item.value === +kato)[language === 'ru' ? 'label' : 'labelKz']
        setSnp(snpName);
      }
    }
  }, [kato])

  useEffect(() => {
    if (selectedRegion && selectedSnp) {
      setRegion(selectedRegion?.[`name${getLang()}`]);
      setSnp(selectedSnp?.[`name${getLang()}`]);
    } else if (selectedRegion && selectedSnp === null) {
      setRegion(selectedRegion?.[`name${getLang()}`]);
      setSnp(null);
    }
  }, [selectedRegion, selectedSnp])

  return (
    <CurrentSnpWrapper bgImage={ProdBackground}>
      <Image src={gerb} noShadow onClick={handleRegionClick} style={{ cursor: 'pointer' }} />
      <SnpInfo>
        <div className="name">{snp || selectedSnp?.[`name${getLang()}`] || t('welcome')}</div>

        <div className="region"> {kato && +kato !== OBLAST_KATO && (region || +(selectedRegion?.[`name${getLang()}`]) || t('choose-village'))}</div>
      </SnpInfo>
    </CurrentSnpWrapper>
  )
}

export default CurrentSnp