import React, { FC, useEffect, useState } from 'react'
import { BasicStatsItem } from '../index.styles'
import { OBLAST_KATO } from '../../../utils/helpers.utils'

const aktobeLink = 'https://app.powerbi.com/view?r=eyJrIjoiZWE1ZDg3MzUtNmZjNi00MGVkLTgyZDAtZTg2ZWEyYTlmYTk5IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9';
const irgizLink = 'https://app.powerbi.com/view?r=eyJrIjoiYzdlNjJmZmUtMTBiNi00NTFjLWFjZGQtYmRiZjM5OTE0NmVlIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9';
const otherLink = 'https://app.powerbi.com/view?r=eyJrIjoiYzA1ZjI0ZTMtOGRhZS00NjVlLTliOTgtMWVmOTk5ODE1MzU4IiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9';

const PSDFullScreen: FC<{ kato: string }> = ({ kato }) => {
  const [link, setLink] = useState<string>(aktobeLink)
  const getLink = () => {
    if (+kato === OBLAST_KATO) {
      setLink(aktobeLink)
    } else if (+kato === 156800000) {
      setLink(irgizLink)
    } else {
      setLink(otherLink)
    }
  }

  useEffect(() => {
    getLink()
  }, [kato])

  return (
    <BasicStatsItem style={{
      width: '100%',
      height: '100%',
    }}>
      <iframe title="PSD iframe" src={link} />
    </BasicStatsItem>
  )
}

export default PSDFullScreen