import React from 'react'
import { BasicStatsItem } from '../index.styles'

const WageFund = () => {
  const link = 'https://app.powerbi.com/view?r=eyJrIjoiYzZhZTUzNDMtMTNjMS00ZDBlLWEzMzgtOWZhMGJiYWM2MTNkIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9'
  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
      }}>
      <iframe title="wage-fund iframe" src={link} />
    </BasicStatsItem>
  )
}

export default WageFund