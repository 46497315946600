import React from 'react'
import { BasicStatsItem } from '../index.styles'

const FinanceAid = () => {
  const link = 'https://app.powerbi.com/view?r=eyJrIjoiMDI4YjIxOTctOTgzMy00ZGFmLTg2NGItYmFmMDk3MmFiYWJjIiwidCI6ImU2MjQzNTFhLWViZGItNDUwMi1hNGEzLTRiZWI3OTYxODBlZiIsImMiOjl9'
  return (
    <BasicStatsItem
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
      }}>
      <iframe title="finance-aid iframe" src={link} />
    </BasicStatsItem>
  )
}

export default FinanceAid